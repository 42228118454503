import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DashNavbar from "./DashNavbar";
import "../css/dash.css";
import Sidebar from "./Sidebar";
import Swal from "sweetalert2";
import Pagination from "react-bootstrap/Pagination";
import Modal from 'react-bootstrap';

const formatDate = (dateString) => {
  if (!dateString) {
    return "Invalid Date";
  }

  try {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }

    return date.toLocaleDateString(undefined, options);
  } catch (error) {
    console.error("Error formatting date:", error);
    return "Invalid Date";
  }
};
const PAGE_SIZE = 8;
const PAGE_SIZE1 = 4;

export default function Jobpostingresult() {
  const [Jobpostingresult, setJobpostingresult] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [Jobrecommendation, setJobrecommendation] = useState([]);
  const navigate = useNavigate();
  const modalRef = useRef(null);
  const [currentPage1, setCurrentPage1] = useState(1);

  const openModal = (jobDetails) => {
    setSelectedJob(jobDetails);
    const modalElement = document.getElementById("staticBackdrop");
    const modalInstance = new window.bootstrap.Modal(modalElement);
    modalInstance.show();
  };

  console.log(window.bootstrap);

  useEffect(() => {
    axios
      .get("https://raasbackend.vetrinahealthcare.com/JobPostingresult_api/")
      .then((response) => {
        setJobpostingresult(response.data);
      })
      .catch((error) => {
        console.error("Error fetching jobposting data:", error);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const id = localStorage.getItem("user_id");
  const role = localStorage.getItem("User_Role")
  useEffect(() => {
    axios
      .post("https://raasbackend.vetrinahealthcare.com/recommend_jobs/", {
        applicant_id: id,
        user_role: role
      })

      .then((response) => {
        setJobrecommendation(response.data);
      })
      .catch((error) => {
        console.error("Error fetching jobposting data:", error);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, role]);

  const filteredJobPostings = Jobpostingresult.filter((jobPosting) =>
    jobPosting.jobtitle.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredJobRecomm = Jobrecommendation.filter((jobPosting) =>
    jobPosting.jobtitle.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const paginatedJobRecomm = filteredJobRecomm.slice(
    (currentPage1 - 1) * PAGE_SIZE1,
    currentPage1 * PAGE_SIZE1
  );

  const paginatedJobPostings = filteredJobPostings.slice(
    (currentPage - 1) * PAGE_SIZE,
    currentPage * PAGE_SIZE
  );


  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageChange1 = (page) => {
    setCurrentPage1(page);
  };


  const handleApply = () => {
    if (!selectedJob || !selectedJob.id) {
      console.error("Invalid job details provided.");

      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "No job selected. Please select a job to apply.",
        timer: 4000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
      return;
    }

    const id = localStorage.getItem("user_id");
    const email = localStorage.getItem("email");
    if (!id || !email) {
      console.error("User id or email not found in localStorage.");
      return;
    }

    axios
      .post("https://raasbackend.vetrinahealthcare.com/jobtitle/", {
        id,
        email,
        jobtitle: selectedJob.jobtitle,
      })
      .then((response) => {
        if (
          response.data.message === "Applicant has already applied for this job"
        ) {
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: "You have already applied for this job.",
            timer: 4000,
            timerProgressBar: true,
            showConfirmButton: false,
          });
        } else {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Thank you for applying for the job.",
            timer: 1000,
            timerProgressBar: true,
            showConfirmButton: false,
          }).then(() => {
            const modal = document.getElementById("staticBackdrop");
            const modalInstance = window.bootstrap.Modal.getInstance(modal);
            if (modalInstance) {
              modalInstance.hide();
            }
            navigate("/dashboard");
          });
          console.log("Response:", response.data);
        }
      })
      .catch((error) => {
        console.error("Error applying for job:", error);

        if (
          error.response?.data?.message ===
          "Applicant has not filled their resume" ||
          error.response?.data?.message === "Personality Quiz not given"
        ) {
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: error.response?.data?.message,
            timer: 4000,
            timerProgressBar: true,
            showConfirmButton: false,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: "An error occurred while applying for the job. Please try again later.",
            timer: 4000,
            timerProgressBar: true,
            showConfirmButton: false,
          });
        }
      });
  };

  return (
    <>
      {/* <div
        style={{ display: "flex", flexDirection: "column", height: "100vh" }}
      > */}
      <DashNavbar />
      <div className="wrapper">
        <Sidebar />
        <div className="main">
          <div className="container-fuild jobresult">
            <h2 className="mb-4 display-6 gradient-text ">Job Openings</h2>
            <div className="row justify-content-center mb-4 ">
              <div className="col-md-4 col-sm-8">
                <div className="input-group">
                  <div className="form-outline w-100  ">
                    <input
                      type="search"
                      className="form-control "
                      placeholder="Search Job Title"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />

                    <button
                      className="btn btn-primary searchicon text-xs "
                    // style={{
                    //   position: "absolute",
                    //   right: "0px",
                    //   top: "0px",
                    // }}
                    >
                      <i className="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div>
                {role === 'Candidate' ? (
                  <div className="row me-2 divresultpos">
                    <h5 className="mb-4 display-7 gradient-text">Job Recommendations</h5>
                    {paginatedJobRecomm.map((Jobrecomm, index) => (
                      <div
                        key={Jobrecomm.id}
                        className="col-md-6 col-lg-5 col-xl-3 col-sm-6 col-12 mb-4"
                      >
                        <div
                          className="card"
                          style={{
                            maxWidth: "18em",
                            maxHeight: "15em",
                            transition: "transform 0.3s",
                          }}
                          onMouseOver={(e) =>
                            (e.currentTarget.style.transform = "scale(1.1)")
                          }
                          onMouseOut={(e) =>
                            (e.currentTarget.style.transform = "scale(1.0)")
                          }
                        >
                          <div className="card-body h-75">
                            <h5 className="card-title mb-2 text-muted medium">
                              {Jobrecomm.jobtitle}
                            </h5>
                            <p className="card-text text-start mb-0 small">
                              <strong>Job Id: </strong>
                              {Jobrecomm.job_id}
                            </p>
                            <p className="card-text text-start mb-2 small">
                              <strong>Posted On: </strong>
                              {formatDate(Jobrecomm.created_at)}
                            </p>
                            <p className="card-text text-start mb-0 small">
                              <strong>
                                <i className="fa-solid fa-briefcase" />
                              </strong>{" "}
                              {Jobrecomm.experience} years
                            </p>
                            <p className="card-text text-start mb-0 small">
                              <strong>
                                <i className="fa-solid fa-clock" />
                              </strong>{" "}
                              {Jobrecomm.role_type}
                            </p>
                            <p className="card-text text-start mb-0 small">
                              <strong>
                                <i className="fa-solid fa-location-dot" />
                              </strong>{" "}
                              {Jobrecomm.job_location}
                            </p>
                          </div>
                          <div className="card-footer d-flex justify-content-center">
                            <button
                              type="button"
                              className="btn btn-info mt-0"
                              onClick={() => openModal(Jobrecomm)}
                            >
                              Details
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="d-flex justify-content-start mt-4">
                      <Pagination>
                        <Pagination.Prev
                          onClick={() => handlePageChange1(currentPage1 - 1)}
                          disabled={currentPage1 === 1}
                        />
                        {Array.from(
                          {
                            length: Math.ceil(Jobrecommendation.length / PAGE_SIZE1),
                          },
                          (_, index) => (
                            <Pagination.Item
                              key={index + 1}
                              active={index + 1 === currentPage1}
                              onClick={() => handlePageChange1(index + 1)}
                            >
                              {index + 1}
                            </Pagination.Item>
                          )
                        )}
                        <Pagination.Next
                          onClick={() => handlePageChange1(currentPage1 + 1)}
                          disabled={
                            currentPage1 ===
                            Math.ceil(Jobrecommendation.length / PAGE_SIZE1)
                          }
                        />
                      </Pagination>
                    </div>
                  </div>
                ) : (
                  <p></p>
                )}
              </div>
            </div>
            <div className="row me-2 divresultpos">
              {paginatedJobPostings.map((Jobposting, index) => (
                <div
                  key={Jobposting.id}
                  className="col-md-6 col-lg-5 col-xl-3 col-sm-6 col-12 mb-4"
                >
                  <div
                    className="card"
                    style={{
                      maxWidth: "18em",
                      maxHeight: "15em",
                      transition: "transform 0.3s",
                    }}
                    onMouseOver={(e) =>
                      (e.currentTarget.style.transform = "scale(1.1)")
                    }
                    onMouseOut={(e) =>
                      (e.currentTarget.style.transform = "scale(1.0)")
                    }
                  >
                    <div className="card-body h-75">
                      <h5 className="card-title mb-2 text-muted medium">
                        {Jobposting.jobtitle}
                      </h5>
                      <p className="card-text text-start mb-0 small">
                        <strong>Job Id: </strong>
                        {Jobposting.job_id}
                      </p>
                      <p className="card-text text-start mb-2 small">
                        <strong>Posted On: </strong>
                        {formatDate(Jobposting.created_at)}
                      </p>
                      <p className="card-text text-start mb-0 small">
                        <strong>
                          <i className="fa-solid fa-briefcase" />
                        </strong>{" "}
                        {Jobposting.experience} years
                      </p>
                      <p className="card-text text-start mb-0 small">
                        <strong>
                          <i className="fa-solid fa-clock" />
                        </strong>{" "}
                        {Jobposting.role_type}
                      </p>
                      <p className="card-text text-start mb-0 small">
                        <strong>
                          <i className="fa-solid fa-location-dot" />
                        </strong>{" "}
                        {Jobposting.job_location}
                      </p>
                    </div>
                    <div className="card-footer d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn btn-info mt-0"
                        onClick={() => openModal(Jobposting)}
                      >
                        Details
                      </button>
                    </div>
                  </div>
                </div>
              ))}
              <div className="d-flex justify-content-start mt-4">
                <Pagination>
                  <Pagination.Prev
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  />
                  {Array.from(
                    {
                      length: Math.ceil(filteredJobPostings.length / PAGE_SIZE),
                    },
                    (_, index) => (
                      <Pagination.Item
                        key={index + 1}
                        active={index + 1 === currentPage}
                        onClick={() => handlePageChange(index + 1)}
                      >
                        {index + 1}
                      </Pagination.Item>
                    )
                  )}
                  <Pagination.Next
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={
                      currentPage ===
                      Math.ceil(filteredJobPostings.length / PAGE_SIZE)
                    }
                  />
                </Pagination>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade "
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
        ref={modalRef}
      >
        <div className="modal-dialog modal-dialog-scrollable modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                {selectedJob
                  ? `${selectedJob.jobtitle}  (${selectedJob.job_id})`
                  : ""}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setSelectedJob(null)} // Reset selectedJob on modal close
              ></button>
            </div>
            <div className="modal-body" style={{ minHeight: "70vh" }}>
              {selectedJob && (
                <>
                  <div className="d-flex mb-2">
                    <strong className="me-2">Role Type:</strong>
                    <span style={{ fontWeight: "normal" }}>
                      {selectedJob.role_type}
                    </span>
                  </div>
                  <div className="d-flex mb-2">
                    <strong className="me-2">Experience:</strong>
                    <span style={{ fontWeight: "normal" }}>
                      {selectedJob.experience} years
                    </span>
                  </div>
                  <div className="d-flex mb-2">
                    <strong className="me-2">Job Location:</strong>
                    <span style={{ fontWeight: "normal" }}>
                      {selectedJob.job_location}
                    </span>
                  </div>
                  <div className="d-flex mb-2">
                    <strong className="me-2">Vacancies:</strong>
                    <span style={{ fontWeight: "normal" }}>
                      {selectedJob.vacancies}
                    </span>
                  </div>

                  <div className=" mb-2 text-start">
                    <strong className="me-2">Description:</strong>
                    <span style={{ fontWeight: "normal" }}>
                      {selectedJob.description}
                    </span>
                  </div>
                </>
              )}
            </div>

            <div className="modal-footer ">
              <button
                type="button"
                className="btn btn-success"
                onClick={() => handleApply(selectedJob)}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
