import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate, useLocation } from "react-router-dom";

export default function AutoLogin() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  const [loginData, setLoginData] = useState({
    email: "",
    user_id: "",
  });

  // Extract query parameters from the URL (user_id and email)
  const extractUrlParams = () => {
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get("email");
    const user_id = searchParams.get("user_id");

    if (email && user_id) {
      setLoginData({ email, user_id });
    } else {
      Swal.fire({
        icon: "error",
        title: "Login Error",
        text: "Missing parameters. Please try again.",
        showConfirmButton: false,
        timer: 4000,
      }).then(() => {
        navigate("/login"); // Redirect to a regular login page if parameters are missing
      });
    }
  };

  // Function to handle login automatically
  const handleAutoLogin = () => {
    if (loginData.email && loginData.user_id) {
      axios
        .post("https://raasbackend.vetrinahealthcare.com/login_auth_view/", loginData)
        .then((response) => {
          const { user, message, error_message } = response.data;

          if (error_message) {
            Swal.fire({
              icon: "error",
              title: "Login Error",
              text: error_message,
              timer: 4000,
              timerProgressBar: true,
              showCancelButton: false,
            });
          } else if (user) {
            const { role } = user;
            const userName = user.name;
            const userId = user.id;
            localStorage.setItem("User_Role", role);
            localStorage.setItem("UserName", userName);
            localStorage.setItem("user_id", userId);
            localStorage.setItem("email", loginData.email);

            Swal.fire({
              icon: "success",
              title: "Login Successful!",
              text: message || "Welcome!",
              timer: 1000,
              timerProgressBar: true,
              showConfirmButton: false,
            }).then(() => {
              // Redirect based on role
              if (role === "Admin") {
                navigate("/Admindashboard");
              } else if (role === "Candidate") {
                navigate("/dashboard");
              } else if (role === "Interviewer") {
                navigate("/InterviewerDashboard");
              } else if (role === "HR") {
                navigate("/HRDashboard");
              }
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Login Error",
            text: "Invalid login. Please try again.",
            timer: 4000,
            timerProgressBar: true,
            showConfirmButton: false,
          }).then(() => {
            navigate("/sign-in"); // Redirect to login page on error
          });
        })
        .finally(() => setLoading(false)); // End loading state after login attempt
    }
  };

  useEffect(() => {
    extractUrlParams(); // Extract parameters from the URL
  }, []);

  useEffect(() => {
    if (loginData.email && loginData.user_id) {
      handleAutoLogin(); // Trigger login once URL parameters are set
    }
  }, [loginData]);

  return (
    <div className="auto-login-page">
      {loading ? (
        <div className="loading-spinner">
          <p>Logging you in, please wait...</p>
        </div>
      ) : (
        <p>Redirecting...</p>
      )}
    </div>
  );
}
